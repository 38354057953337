<template>
  <div>
    <TitleWrapper :filter-option="false" title="Notifications" :display-breadcrumb="true" />
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <!-- CLass Creation/ DEactivation Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for new class creation/deactivation
        </p>
      </div>
      <div
        v-if="!isLoadingClassCreationDeactivation"
        class="mt-5 rounded-lg border border-primary-grey"
      >
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="classCreationList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingClassCreationDeactivation"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(classCreationCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Section Creation Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for section creation/deactivation
        </p>
      </div>
      <div v-if="!isLoadingSectionCreation" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>

        <NotificationTableType2 v-else :notification-list="sectionCreationList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingSectionCreation"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(sectionCreationCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- Promotion Demotion from section Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for promotion/demotion from section
        </p>
      </div>
      <div v-if="!isLoadingSectionPromotion" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="sectionPromotionList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingSectionPromotion"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(sectionPromotionCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />
    </div>
    <AddExamTemplateType2
      v-if="showClassCreationModal"
      :show-model="showClassCreationModal"
      label="class creation/deactivation"
      @handleClick="toggleShowClassCreationModal"
      @update="addClassCreation"
    />
    <AddExamTemplateType2
      v-if="showSectionCreationModal"
      label="section creation/deactivation"
      :show-model="showSectionCreationModal"
      @handleClick="toogleShowSectionCreationModal"
      @update="addSectionCreation"
    />
    <AddExamTemplateType2
      v-if="showSectionPromotionModal"
      label="promotion/demotion from section"
      :show-model="showSectionPromotionModal"
      @handleClick="toggleShowSectionPromotionModal"
      @update="addSectionPromotion"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import AddExamTemplateType2 from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationTableType2 from '@/src/components/Settings/notifications/NotificationTableType2.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    AddExamTemplateType2,
    NotificationTableType2,
    NoRecordFound,
  },

  mixins: [GeneralUtil],
  page: {
    title: 'Notification | Class',
  },
  data() {
    return {
      notifications: 'notifications',
      isLoadingClassCreationDeactivation: false,
      isLoadingSectionCreation: false,
      isLoadingSectionPromotion: false,
      classCreationCount: 20,
      sectionCreationCount: 20,
      sectionPromotionCount: 20,
      filteredRecordLimit: 5,
      showClassCreationModal: false,
      showSectionCreationModal: false,
      showSectionPromotionModal: false,
      classCreationList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      sectionCreationList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      sectionPromotionList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
    }
  },
  created() {
    this.setRightBar()
  },
  methods: {
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'Notifications',
          buttons: [
            {
              title: 'Create New Notification',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CLASS_CREATION_DEACTIVATION',
                  action: {
                    func: () => {
                      this.toggleShowClassCreationModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'SECTION_CREATION_DEACTIVATION',
                  action: {
                    func: () => {
                      this.toogleShowSectionCreationModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'SECTION_PROMOTION',
                  action: {
                    func: () => {
                      this.toggleShowSectionPromotionModal()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    addClassCreation(e) {
      this.classCreationList.push(e)
    },

    addSectionCreation(e) {
      this.sectionCreationList.push(e)
    },

    addSectionPromotion(e) {
      this.sectionPromotionList.push(e)
    },

    filterRecord() {},

    toggleShowClassCreationModal(eventName) {
      this.showClassCreationModal = !this.showClassCreationModal
    },

    toogleShowSectionCreationModal(eventName) {
      this.showSectionCreationModal = !this.showSectionCreationModal
    },

    toggleShowSectionPromotionModal(eventName) {
      this.showSectionPromotionModal = !this.showSectionPromotionModal
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
